gsap.registerPlugin(ScrollTrigger);

class HorizontalScrollController {
    constructor(containerSelector, menueItemSelector, sectionSelector, pinScrolltrigger) {
        this.containerSelector = containerSelector;
        this.menueItemSelector = menueItemSelector;
        this.sectionSelector = sectionSelector;
        this.pinScrolltrigger = pinScrolltrigger;

        this.pinWrap = document.querySelector(this.containerSelector);
        this.scrollingContainer = this.pinWrap;

        this.scrollingContainer = this.pinWrap;
        this.menueItems = Array.from(document.querySelectorAll(this.menueItemSelector));
        this.sections = gsap.utils.toArray(this.sectionSelector);
        this.sectionsMap = new Map();
        this.itemsMap = new Map();

        this.setupSections();
        this.setupMenueClick();

        if (ScrollTrigger.isTouch === 1) {
            ScrollTrigger.normalizeScroll(true);
        }
    }

    initHorizontalScroll() {
        if (this.pinWrap != null) {
            this.setupHorizontalScroll();
            window.addEventListener("resize", () => {
                this.refresh();
            });
        }
    }

    // vermeidet Lücken bei der Grössenänderung
    refresh() {
        this.horizontalScrollLength = this.pinWrap.offsetWidth - window.innerWidth;
        ScrollTrigger.refresh();
    }

    setupHorizontalScroll() {
        this.horizontalScrollLength = this.pinWrap.offsetWidth - window.innerWidth;

        this.horizontalScrollTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: this.containerSelector,
                start: "top top",
                end: () => `+=${this.horizontalScrollLength}`,
                pin: this.pinScrolltrigger,
                invalidateOnRefresh: true,
                scrub: true,
                pinSpacing: true,
                onUpdate: self => {
                    const activeMenueItemSelector = self.progress === 1 ? `${this.menueItemSelector}--entstehung` : `${this.menueItemSelector}--konzept`;
                    const menueItemElement = document.querySelector(activeMenueItemSelector);
                    if (menueItemElement) {
                        this.setActiveMenueItem(menueItemElement);
                    }
                }
            }
        });

        this.horizontalScrollTimeline.to(this.scrollingContainer, {
            x: () => -this.horizontalScrollLength,
            ease: 'none',
        });
    }

    setupSections() {
        this.sections.forEach((section, index) => {
            this.sectionsMap.set(section, this.menueItems[index]);
            this.itemsMap.set(this.menueItems[index], section);
        });

        this.sections.forEach((section, i) => {
            ScrollTrigger.create({
                trigger: section,
                start: "top center",
                end: "Top center",
                onEnter: () => {
                    if (!section.matches(this.sectionSelector)) return;
                    this.setActiveMenueItem(this.sectionsMap.get(section));
                },
                onLeaveBack: () => {
                    if (!section.matches(this.sectionSelector)) return;
                    this.setActiveMenueItem(this.sectionsMap.get(this.sections[i - 1]));
                }
            });
        });
    }

    setupMenueClick() {
        const header = document.querySelector(".header__element--projekt");
        this.menueItems.forEach((item) => {
            item.addEventListener("click", () => {
                const targetSection = this.itemsMap.get(item);
                if (targetSection) {
                    this.scrollToSection(targetSection);
                } else {
                    console.error(`Section nicht gefunden: ${item}`);
                }
            });
        });
    }

    setActiveMenueItem(element) {
        this.menueItems.forEach((item) => {
            if (element === item) {
                item.setAttribute("data-menue-projekt", "aktiv");
            } else {
                item.removeAttribute("data-menue-projekt");
            }
        });
    }

    scrollToSection(section) {
        function getPaddingTopValue() {
            const width = window.innerWidth;

            if (width >= 2562) {
                // Gross
                const projektBeschriftung = document.querySelector('.projekt__beschriftung');
                console.log(projektBeschriftung.offsetHeight);
                return 62 - (62 - projektBeschriftung.offsetHeight) + 30;
            } else if (width >= 1440) {
                // Desktop
                const projektBeschriftung = document.querySelector('.projekt__beschriftung');
                console.log(projektBeschriftung.offsetHeight);
                return 62 - (62 - projektBeschriftung.offsetHeight) + 30;
            } else if (width >= 1024) {
                // TabletQuer
                const projektBeschriftung = document.querySelector('.projekt__beschriftung');
                console.log(projektBeschriftung.offsetHeight);
                return 62 - (62 - projektBeschriftung.offsetHeight) + 30;
            }
            else if (width >= 768) {
                // TabletHoch (62 + 46 + 15)
                const projektBeschriftung = document.querySelector('.projekt__beschriftung');
                console.log(`height: ${projektBeschriftung.offsetHeight}`);
                return projektBeschriftung.offsetHeight + 30;
            } else {
                // Smartphone
                const projektBeschriftung = document.querySelector('.projekt__beschriftung');
                console.log(`height: ${projektBeschriftung.offsetHeight}`);
                return 147 + (projektBeschriftung.offsetHeight + 30);
            }
        }

        let paddingTopValueBau = getPaddingTopValue();
        console.log(`paddingTopValueBau: ${paddingTopValueBau}`);
        let paddingLeftKonzeptElement = document.querySelector('.projekt__konzept-bild');
        let paddingLeftKonzept = gsap.getProperty(paddingLeftKonzeptElement, "marginLeft");

        console.log(`paddingLeftKonzept: ${paddingLeftKonzept}`);
        console.log(`paddingTopValueBau: ${paddingTopValueBau}`);

        const hasKonzept = document.querySelector("#konzept");
        const hasEntstehung = document.querySelector("#entstehung");

        let offsetTop = 0;

        if (section.matches('#entstehung')) {
            if (hasKonzept && hasEntstehung) {
                // Wenn sowohl "#konzept" als auch "#entstehung" vorhanden sind
                offsetTop = (hasKonzept.offsetTop - paddingLeftKonzept) + paddingTopValueBau + this.horizontalScrollLength + hasKonzept.clientHeight;
                // console.log("Scrollen zu Entstehung, mit Konzept", offsetTop, `${hasKonzept.offsetTop + paddingTopValueBau} + ${this.horizontalScrollLength} + ${hasKonzept.clientHeight}`);
            } else if (hasEntstehung) {
                // Wenn nur "#entstehung" vorhanden ist
                offsetTop = (hasKonzept.offsetTop - paddingLeftKonzept) + paddingTopValueBau;
                // console.log("Scrollen zu Entstehung: ", offsetTop);
            }
        } else if (section.matches('#konzept')) {
            if (hasKonzept) {
                // Wenn nur "#konzept" vorhanden ist
                offsetTop = (hasKonzept.offsetTop - paddingLeftKonzept) + paddingTopValueBau;
                // console.log("Scrollen zu Konzept: ", offsetTop, `(${hasKonzept.offsetTop} + ${paddingTopValueBau})`);
            }
        }

        window.scrollTo({
            top: offsetTop,
            behavior: "smooth"
        });
    }
}